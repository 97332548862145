import { endpointIdFactory } from '@seccl/api-swr';
import { AuthApi } from '@seccl/third-party-clients/generatedPlatformUIApiClient';

import { secclPlatformUIApiFactory } from '../secclPlatformUIApiFactory';

const authApiIds = endpointIdFactory('auth', AuthApi);

export const platformAuthApi = secclPlatformUIApiFactory.createAxiosOpenApiController(
  authApiIds.controllerKey,
  AuthApi
);
