/* tslint:disable */
/* eslint-disable */
/**
 * Seccl Platform API
 * This is the documented API routes available through NextJS in the Seccl Platform UI based on the OpenAPI 3.1 specification.
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from './api';
export * from './configuration';
