import React, { useCallback } from 'react';

import type { Activity, ActivityType } from '../context/activity.context';
import { ActivityContext } from '../context/activity.context';

/**
 * Provider which keeps a record of the latest user activity detected. Does not keep a full history.
 * Should not be relied upon for total accuracy as it only records activity once per second.
 */
export const ActivityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [latestRecordedActivity, setLatestRecordedActivity] = React.useState<Activity>({
    time: Date.now(),
    type: 'init',
  });
  const debounceOneSecond = 1000;

  // Only record new activity every second for performance.
  const registerNewActivity = useCallback(
    (activityType: ActivityType) => {
      const now = Date.now();

      if (now - latestRecordedActivity.time >= debounceOneSecond) {
        setLatestRecordedActivity({ time: now, type: activityType });
      }
    },
    [latestRecordedActivity.time]
  );

  const handleKeyDown = useCallback(() => registerNewActivity('keydown'), [registerNewActivity]);
  const handlePointerMove = useCallback(() => registerNewActivity('pointermove'), [registerNewActivity]);
  const handleScroll = useCallback(() => registerNewActivity('scroll'), [registerNewActivity]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleKeyDown, handlePointerMove, handleScroll]);

  return (
    <ActivityContext.Provider value={{ latestRecordedActivity, registerNewActivity }}>
      {children}
    </ActivityContext.Provider>
  );
};
