import React, { useContext } from 'react';
import { datadogRum } from '@datadog/browser-rum-slim';
import { useRouter } from 'next/router';

import { platformAuthApi } from '../api/platformUI/auth';
import { ActivityContext } from '../context/activity.context';
import { useInterval } from '../hooks/useInterval';
import { routes } from '../routes';

const SESSION_LENGTH_SECONDS = 580;
const SESSION_LENGTH_MILLISECONDS = SESSION_LENGTH_SECONDS * 1000;
const SESSION_CHECK_INTERVAL_SECONDS = 5;
const SESSION_CHECK_INTERVAL_MILLISECONDS = SESSION_CHECK_INTERVAL_SECONDS * 1000;

/**
 * Checks the session every {@link SESSION_CHECK_INTERVAL_SECONDS} seconds. If the user has been active at any point in the previous
 * {@link SESSION_LENGTH_SECONDS} seconds, then we refresh the session.
 */
export const SessionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { clientFetch } = platformAuthApi.refresh.useMutation();
  const { latestRecordedActivity } = useContext(ActivityContext);
  const [latestSessionRefresh, setLatestSessionRefresh] = React.useState<number>(Date.now());
  const router = useRouter();

  const refreshSession = React.useCallback(async () => {
    try {
      await clientFetch();
    } catch (e: any) {
      if ('message' in e) {
        datadogRum.addAction('Network error attempting to invoke the session refresh endpoint', {
          activity: new Date(latestRecordedActivity.time).toISOString(),
          expiry: new Date(latestSessionRefresh + SESSION_LENGTH_MILLISECONDS).toISOString(),
          message: e.message,
        });
      }
    }
  }, [clientFetch, latestRecordedActivity.time, latestSessionRefresh]);

  const checkSessionForExpiry = async () => {
    const now = Date.now();
    const sessionIsAboutToExpire = latestSessionRefresh + SESSION_LENGTH_MILLISECONDS <= now;
    const sessionHasHadActivity = latestRecordedActivity.time + SESSION_LENGTH_MILLISECONDS >= now;

    if (!sessionHasHadActivity) {
      datadogRum.addAction('session_timeout', {
        activity: new Date(latestRecordedActivity.time).toISOString(),
        expiry: new Date(latestSessionRefresh + SESSION_LENGTH_MILLISECONDS).toISOString(),
      });
      router.push(routes.auth.auth0.logout);
    }

    if (sessionIsAboutToExpire && sessionHasHadActivity) {
      await refreshSession();
      setLatestSessionRefresh(Date.now());

      const humanFriendlyDiff = `User was active ${Number((now - latestRecordedActivity.time) / 1000).toFixed(
        0
      )} seconds before the session expired.`;
      datadogRum.addAction('session_refreshed', {
        activity: new Date(latestRecordedActivity.time).toISOString(),
        expiry: new Date(latestSessionRefresh + SESSION_LENGTH_MILLISECONDS).toISOString(),
        message: humanFriendlyDiff,
      });
    }
  };

  useInterval(checkSessionForExpiry, SESSION_CHECK_INTERVAL_MILLISECONDS);

  return <>{children}</>;
};
