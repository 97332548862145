import React from 'react';

export type ActivityType = 'init' | 'keydown' | 'pointermove' | 'scroll' | 'iframeinteraction';

export type Activity = {
  type: ActivityType;
  time: number;
};

export interface IActivityContext {
  latestRecordedActivity: Activity;
  registerNewActivity: (activityType: ActivityType) => void;
}

export const ActivityContext = React.createContext<IActivityContext>({
  latestRecordedActivity: { time: Date.now(), type: 'init' },
  registerNewActivity: () => null,
});
