/* tslint:disable */
/* eslint-disable */
/**
 * Seccl Platform API
 * This is the documented API routes available through NextJS in the Seccl Platform UI based on the OpenAPI 3.1 specification.
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface PlatformUIErrorResponse
 */
export interface PlatformUIErrorResponse {
  /**
   *
   * @type {string}
   * @memberof PlatformUIErrorResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface PlatformUIGenerateMorpheusAuthTokenResponse
 */
export interface PlatformUIGenerateMorpheusAuthTokenResponse {
  /**
   * Authorisation token
   * @type {string}
   * @memberof PlatformUIGenerateMorpheusAuthTokenResponse
   */
  token: string;
  /**
   * Expiry datetime of the authorisation token
   * @type {number}
   * @memberof PlatformUIGenerateMorpheusAuthTokenResponse
   */
  expires: number;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Refreshes the active session.
     * @summary Refreshes the active session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refresh: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/baq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Refreshes the active session.
     * @summary Refreshes the active session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refresh(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['AuthApi.refresh']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * Refreshes the active session.
     * @summary Refreshes the active session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refresh(options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.refresh(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Refreshes the active session.
   * @summary Refreshes the active session.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public refresh(options?: RawAxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .refresh(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MorpheusApi - axios parameter creator
 * @export
 */
export const MorpheusApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Generates an auth token, that authenticates the user and authorises access to the Morpheus API.
     * @summary Generates an auth token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    token: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/morpheus/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MorpheusApi - functional programming interface
 * @export
 */
export const MorpheusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MorpheusApiAxiosParamCreator(configuration);
  return {
    /**
     * Generates an auth token, that authenticates the user and authorises access to the Morpheus API.
     * @summary Generates an auth token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async token(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformUIGenerateMorpheusAuthTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.token(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['MorpheusApi.token']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MorpheusApi - factory interface
 * @export
 */
export const MorpheusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MorpheusApiFp(configuration);
  return {
    /**
     * Generates an auth token, that authenticates the user and authorises access to the Morpheus API.
     * @summary Generates an auth token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    token(options?: RawAxiosRequestConfig): AxiosPromise<PlatformUIGenerateMorpheusAuthTokenResponse> {
      return localVarFp.token(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MorpheusApi - object-oriented interface
 * @export
 * @class MorpheusApi
 * @extends {BaseAPI}
 */
export class MorpheusApi extends BaseAPI {
  /**
   * Generates an auth token, that authenticates the user and authorises access to the Morpheus API.
   * @summary Generates an auth token.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MorpheusApi
   */
  public token(options?: RawAxiosRequestConfig) {
    return MorpheusApiFp(this.configuration)
      .token(options)
      .then(request => request(this.axios, this.basePath));
  }
}
